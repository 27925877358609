import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./Dropdown.module.scss";
import { useTranslation } from "react-i18next";
import { OptionModel } from "models/baseModel";

interface DropdownProps {
  /** 單一元件id */
  id?:string;
  onChange: (msg: string | null) => void;
  placeholder?: string | null;
  options: OptionModel[];
  /** 預設選擇id */
  defaultId?: string | null;
  /** title */
  title?: string;
  valid?: boolean;
  disabled?: boolean;
  /** 浮動title */
  isFloatTitle?: boolean;
  /** 搜尋框 */
  showSearch?: boolean;
  /** clearIconDisplay */
  clearIconDisplay?: boolean;
}

const Dropdown: FC<DropdownProps> = ({
  valid = true,
  showSearch = true,
  id = '',
  clearIconDisplay = true,
  ...props
}) => {
  const [searchKey, setSearchKey] = useState("");
  const [searchId, setSearchId] = useState(props.defaultId);
  const [filterOptions, setFitlerOptions] = useState(props.options);
  const [hide, setHide] = useState(true);
  const boxRef = useRef<HTMLDivElement>(null);
  const { defaultId } = props;
  const { t } = useTranslation();
  const handleChangeKey = (key: string | null) => {
    setSearchKey(key || "");
    // 複製新陣列
    const newOptions = [...props.options];
    if (key) {
      const selItems = newOptions.filter((p) =>
        p.text.toLocaleLowerCase().includes(key.toLocaleLowerCase())
      );
      setFitlerOptions(selItems);
    } else {
      setFitlerOptions(newOptions);
    }
  };

  const clickItem = (e: any, item: OptionModel) => {
    // 清除search 文字
    // handleChangeKey("");
    e.stopPropagation();
    setSearchKey(item.text);
    setSearchId(item.id);
    setHide(true);
    // inputRef.current!.value = item.text;
    props.onChange(item.id);
  };

  useEffect(() => {
    // 複製新陣列
    const newOptions = [...props.options];
    setFitlerOptions(newOptions);
    
    if (props.options) {
        const selItem = newOptions.find((p) => p.id === defaultId);
      if (selItem) {
        setSearchKey(selItem.text);
        setSearchId(selItem.id);
      } else {
        setSearchKey("");
      }
    }

    if (!defaultId) {
      setSearchId("");
    }
  }, [props.options, defaultId]);

  const handleOtherClick = (e: any) => {
    if(hide){
      document.body.removeEventListener("click", handleOtherClick);
      return;
    }
    if (!boxRef.current?.contains(e.target)) {
      setHide(true);
      if (!searchId) {
        setSearchKey("");
      } else {
        const newOptions = [...props.options];
        // 原本就有值搜尋到一半直接點外面
        const selItem = newOptions.find((p) => p.id === searchId);
        if (selItem) {
          setSearchKey(selItem.text);
        }
      }
    }
  };
  useEffect(() => {
 
    if(!hide){

      document.body.addEventListener("click", handleOtherClick);
    }
    return () => {
      document.body.removeEventListener("click", handleOtherClick);
    };
  }, [hide]);

  /** 打開視窗flow */
  const openFlow = () => {
    setHide(false);
    handleChangeKey("");
  };

  /** 按下enter */
  const keyDown = (e:React.KeyboardEvent<HTMLInputElement>)=>{
    if(e.code === 'Enter' ||
      e.code === 'NumpadEnter'
    ){
      if(filterOptions.length === 1){
        clickItem(e, filterOptions[0]);
      }
    }
  }
  return (
    <div
      ref={boxRef}
      className={
        `${styles['dropdown-content-box']} ` +
        `${valid ? "" : "invalid"} ` +
        `${props.disabled ? "disabled" : ""} `
      }
      onClick={openFlow}
    >
      <input
        className={`display-input `}
        type="text"
        value={searchKey}
        onKeyDown={($event)=>{ keyDown($event)}}
        onChange={($event) => handleChangeKey($event.target.value)}
        placeholder={props.placeholder || ""}
      />
      {/* 浮動title */}
      {props.isFloatTitle && (
        <div className="placeholder-item">{props.placeholder}</div>
      )}
      {searchKey && !props.disabled && clearIconDisplay && (
        <div
          className="clear-btn"
          onClick={(e) => {
            clickItem(e, { id: "", text: "" });
          }}
        >
          <img
            alt="input-clear-icon.svg"
            src="/assets/images/buttonIcon/input-clear-icon.svg"
          />
        </div>
      )}
      <div className="dropdown-icon"></div>

      {/* 搜尋清單 */}
      {!hide && (
        <div className="dropdown-box">
          <div className="option-box scroll">
            {/* title */}
            {props.title && <div className="option-title">{props.title}</div>}
            {filterOptions?.map((p, filterIndex) => {
              return (
                <div
                  className={
                    "option-item " + (searchId === p.id ? "active" : "")
                  }
                  key={`${filterIndex}_${p.id}`}
                  onClick={($event) => clickItem($event, p)}
                >
                  {p.text}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
