import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./NewDropDown.module.scss";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { OptionModel } from "models/baseModel";

interface NewDropdownProps {
  onChange: (msg: string | null) => void;
  placeholder?: string | null;
  options: OptionModel[];
  /** 預設選擇id */
  defaultId?: string | null;
  /** title */
  title?: string;
  valid?: boolean;
  disabled?: boolean;
  /** 浮動title */
  isFloatTitle?: boolean;
  /** 搜尋框 */
  showSearch?: boolean;
}

const NewDropdown: FC<NewDropdownProps> = ({
  valid = true,
  showSearch = true,
  ...props
}) => {
  const [searchKey, setSearchKey] = useState("");
  const [displayWord, setDisplayWord] = useState("");
  const [searchId, setSearchId] = useState(props.defaultId);
  const [filterOptions, setFitlerOptions] = useState(props.options);
  const [hide, setHide] = useState(true);
  const boxRef = useRef<HTMLDivElement>(null);
  const { defaultId } = props;
  const { t } = useTranslation();

  const handleChangeKey = (key: string | null) => {
    setSearchKey(key || "");
    // 複製新陣列
    const newOptions = [...props.options];
    if (key) {
      const selItems = newOptions.filter((p) =>
        p.text.toLocaleLowerCase().includes(key.toLocaleLowerCase())
      );
      setFitlerOptions(selItems);
    } else {
      setFitlerOptions(newOptions);
    }
  };

  const clickItem = (e: any, item: OptionModel) => {
    // 清除search 文字
    // handleChangeKey("");
    e.stopPropagation();
    setDisplayWord(item.text);
    setSearchId(item.id);
    setHide(true);
    // inputRef.current!.value = item.text;
    props.onChange(item.id);
  };

  useEffect(() => {
    // 複製新陣列
    const newOptions = [...props.options];
    setFitlerOptions(newOptions);

    if (props.options) {
      const selItem = newOptions.find((p) => p.id === defaultId);
      if (selItem) {
        setDisplayWord(selItem.text);
        setSearchId(selItem.id);
      } else {
        setDisplayWord("");
      }
    }

    if (!defaultId) {
      setSearchId("");
    }
  }, [props.options, defaultId]);

  useEffect(() => {
    const handleOtherClick = (e: any) => {
      if (!boxRef.current?.contains(e.target)) {
        setHide(true);
      }
    };
    document.body.addEventListener("click", handleOtherClick);
    return () => {
      document.body.removeEventListener("click", handleOtherClick);
    };
  }, []);

  /** 打開視窗flow */
  const openFlow =()=>{
    setHide(false);
    handleChangeKey("");
  }

  return (
    <div
      ref={boxRef}
      className={
        `${styles["new-dropdown-content-box"]} ` +
        `${valid ? "" : "invalid"} ` +
        `${props.disabled ? "disabled" : ""} `
      }
      onClick={openFlow}
    >
      <input
        className={`display-input `}
        type="text"
        readOnly
        value={displayWord}
        placeholder={props.placeholder || ""}
        title={displayWord}
      />
      {/* 浮動title */}
      {props.isFloatTitle && (
        <div className="placeholder-item">{props.placeholder}</div>
      )}
      {displayWord && !props.disabled && (
        <div
          className="clear-btn"
          onClick={(e) => {
            clickItem(e, { id: "", text: "" });
          }}
        >
          <img
            alt="input-clear-icon.svg"
            src="/assets/images/buttonIcon/input-clear-icon.svg"
          />
        </div>
      )}
      <div className="dropdown-icon"></div>

      {/* 搜尋清單 */}
      {!hide && (
        <div className="dropdown-box">
          {/* 篩選 */}
          {showSearch && (
            <div className="search-box">
              <input
                className={`seach-input `}
                type="text"
                value={searchKey}
                onChange={($event) => handleChangeKey($event.target.value)}
                placeholder={`${t("SEARCH")}...`}
              />
              <img
                alt="search"
                src="/assets/images/buttonIcon/search-icon.svg"
              />
            </div>
          )}
          <div className="option-box scroll">
            {/* title */}
            {props.title && <div className="option-title">{props.title}</div>}
            {filterOptions?.map((p, filterIndex) => {
              return (
                <div
                  className={
                    "option-item " + (searchId === p.id ? "active" : "")
                  }
                  key={`${filterIndex}_${p.id}`}
                  onClick={($event) => clickItem($event, p)}
                >
                  {p.text}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default NewDropdown;
